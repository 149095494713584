import Carousel from 'react-bootstrap/Carousel';
import hillhallImage from '../assets/images/hill_hall.png'
import locationImage1 from '../assets/images/location1.jpeg'
import locationImage2 from '../assets/images/location2.jpeg'
import locationImage3 from '../assets/images/location3.jpeg'
import studio1 from '../assets/images/Studio1.jpeg'
import studio3 from '../assets/images/studio3.jpeg'
import studio4 from '../assets/images/studio4.jpeg'
import liveRoom from '../assets/images/liveRoom.jpeg'
import piano from '../assets/images/piano.jpeg'
import SpotifyPlayer from './SpotifyPlayer';
import sarc from '../assets/images/sarc.jpg';

const PhotoCarousel = () => {
    return (
        <Carousel>
            <Carousel.Item>
                <div className='c_carousel_div'>
                    <img
                        className="c_carousel_div__img"
                        src={sarc}
                        alt="Me laughing while recording at the desk in SARC Belfast"
                    />
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='c_carousel_div'>
                    <img
                        className="c_carousel_div__img"
                        src={piano}
                        alt="Me playing piano"
                    />
                    
                </div>
            </Carousel.Item>
            <Carousel.Item> 
                <div className='c_carousel_div'>
                    <img
                        className="c_carousel_div__img"
                        src={locationImage1}
                        alt="location recording"
                    />
                </div>
            </Carousel.Item>
            <Carousel.Item> 
                <div className='c_carousel_div'>
                   <div className='c_carousel_div__spotifyPlayer'>
                        <SpotifyPlayer height='500px'/>
                    </div>  
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='c_carousel_div'>
                    <img
                        className="d-block w-90 carousel-img"
                        src={locationImage2}
                        alt="Recording drums"
                    />
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='c_carousel_div'>
                    <img
                        className="c_carousel_div__img"
                        src={hillhallImage}
                        alt="Mixing Audio"
                    />
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='c_carousel_div'>
                    <img
                        className="c_carousel_div__img"
                        src={liveRoom}
                        alt="Guitar and amps"
                    />
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='c_carousel_div'>
                    <img
                        className="c_carousel_div__img"
                        src={locationImage3}
                        alt="Filming a music video"
                    />
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='c_carousel_div'>
                    <img
                        className="c_carousel_div__img"
                        src={studio1}
                        alt="keyboard and computer"
                    />
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='c_carousel_div'>
                    <img
                        className="c_carousel_div__img"
                        src={studio3}
                        alt="preamp, protools and mixing desk"
                    />
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='c_carousel_div'>
                    <img
                        className="c_carousel_div__img"
                        src={studio4}
                        alt="Glasswings in the control room, keyboard set up and bass guitar and pedals on the floor "
                    />
                </div>
            </Carousel.Item>
        </Carousel>
    )
}

export default PhotoCarousel