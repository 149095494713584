const Clients = () => {
    return (
        <div className="p_generic_container ">
            <h2 className="c_service_header page-header">Clients</h2>
                <div className="c_clients_body">
                    <h3 className="c_clients_body__title">These are just a few of the amazing clients I have had the pleasure of working with:</h3>
                    <a href='https://open.spotify.com/album/6ckh3U2IE879CgHJMSlqZ6?highlight=spotify%3Atrack%3A1dIeLuOPVSOL9sr13Stwwx' target="_blank" rel="noreferrer">Joshua Burnside</a> ~
                    <a href='https://open.spotify.com/track/5TLzAkWXjNsUr5jShamBab?si=OO2GgIsTRIiZWnHnHJLv5g&nd=1' target="_blank" rel="noreferrer">Sam Wickens</a> ~
                    <a href='https://open.spotify.com/track/5Zy5sGUe1TJpasEroIgfte?si=VvlhuXyNTrqoI79hpCoDlg&nd=1' target="_blank" rel="noreferrer">Peter J Macauley</a> ~ Alana Henderson ~
                    <a href='https://open.spotify.com/album/20ymUKihC4QCHA7u5jWvGs?si=LkCW6TVqSIyQFyEG4jqt2Q&nd=1' target="_blank" rel="noreferrer">Simon Herron</a> ~
                    <a href='https://open.spotify.com/track/3MYair6gFGvXtx9GrondfW?si=-fSpznP6TiioXStgp1g6sQ&nd=1' target="_blank" rel="noreferrer">Master & Dog</a> ~
                    <a href='https://open.spotify.com/track/1tuxZ8kfqdGHs5KJMfXJ6G?si=Dq_5xupoQ1S0kdW29v2zrw&nd=1' target="_blank" rel="noreferrer">Ryan McMullan</a> ~
                    <a href='https://open.spotify.com/artist/2HKJvNnbAyGba7Au7QynC9?si=g87Tip4rQMCPkAv074CrsA&nd=1' target="_blank" rel="noreferrer">Rory Nellis</a> ~
                    <a href='https://open.spotify.com/track/42cJlqazHsD1g52Soh5iKy?si=PTOlodPsS1WjY0cZbxs2YQ&nd=1' target="_blank" rel="noreferrer">Tour Alaska</a> ~
                    <a href='https://open.spotify.com/track/7uOTDWk8zZjbFHCnEbcV4l?si=DSsY0mASTIq5Fdvp2cJBsg&nd=1' target="_blank" rel="noreferrer">Glass Wings</a> ~
                    <a href='https://open.spotify.com/track/6BPzU6x8Zyta0YsFOeKr1o?si=8q5lUCpfQrCjOmlCyKcbFg&nd=1' target="_blank" rel="noreferrer">No Oil Paintings</a> ~
                    <a href='https://open.spotify.com/track/4EOnMEcCVTGL3NXqkQ2ax8?si=WTeNrOcGRzyYkWFbMPMvYg&nd=1' target="_blank" rel="noreferrer">Kippysmuse</a> ~
                    <a href='https://open.spotify.com/track/5kV3vvRPivZivtcZOsep3L?si=glULKJHGRfypFolcD-Wpyg&nd=1' target="_blank" rel="noreferrer">Drew Makes Noise</a> ~
                    <a href='https://open.spotify.com/album/4HXueDmPQ7vgrnOvqOZkdM?si=7boR4V3AT12LegrAH0nVlA&nd=1' target="_blank" rel="noreferrer">Arvo Party</a> ~
                    <a href='https://open.spotify.com/artist/0ZBHk3tcWcUpP3775TW7Ma?si=HsXfRZpIT-2ARJZHVC1Gig&nd=1' target="_blank" rel="noreferrer">Philip Watts d'Alton</a> ~
                </div>

                <div className="c_clients_body__videoDiv">
                    <iframe className='c_clients_iframe__video' title="vimeo-player" src="https://player.vimeo.com/video/241861247?h=671e72ffdc"></iframe>
                </div>
                <div className="c_clients_body__videoDiv">
                    <iframe className='c_clients_iframe__video' src="https://www.youtube.com/embed/0pwWuXkY-rs" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                </div>
                <div className="c_clients_body__videoDiv">
                    <iframe  className='c_clients_iframe__video' src="https://www.youtube.com/embed/7XMVnRizLYU" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                </div>
                <div className="c_clients_body__videoDiv mb-2">
                    <iframe  className='c_clients_iframe__video' src="https://www.youtube.com/embed/YRdVQfS4db8" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                </div>
        </div>
    )
}

export default Clients