interface ISpotifyPlayerProps {
    height?: string
    width?: string
}

const SpotifyPlayer = ({height, width}: ISpotifyPlayerProps) => {
    return (
        <div>
            <iframe 
                src="https://open.spotify.com/embed/playlist/6Jencnl7ZE1J6nucLqJg1n?utm_source=generator" 
                width={width ?? "100%"}
                height={height ?? '402'} 
                frameBorder="0" 
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                title="Recordings and Mixes"
                loading="lazy">
             </iframe>
        </div>
    )
}

export default SpotifyPlayer