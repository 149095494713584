import fig8 from '../assets/images/fig8.png';

const About = () => {

    const yearsInBelfast = new Date().getFullYear() - 2006

    return (
        <div className='p_generic_container'>
            <h1 className='c_service_header page-header'>About Me</h1>
            <div className="c_about_div">
                <p className="text-l text-center mb-4 spiel">I'm a music producer, recording/mix engineer and musician originally from Dublin. I've been living and working in Belfast for the past {yearsInBelfast} years. I have been producing music for the past {yearsInBelfast + 5} years. As well as this experience I have a degree and masters degree in Sonic Arts and Sound Design. My research in this is something that I like to incorporate into my recording process. I have a great interest in found sounds and soundscapes and finding those accidental sounds that come from instruments and making a feature of these. Creating a sonic depth in recordings is something that I have a great love for.</p>
                <p className="text-l text-center mb-4 spiel">I love working with all genres of music….except metal. I don’t do metal.</p>
                <p className="text-l text-center mb-4 spiel">I am very lucky to have worked with some wonderful people over the years.  Alana Henderson, Joshua Burnside, Sam Wickens, Ryan McMullan, Foy Vance, Master & Dog and Simon Herron, Rory Nellis, Arvoparty.. to name but a few.</p>
                <p className="text-l text-center mb-4 spiel">I especially love working with people who are open to being a bit experimental and just enjoy the process of making music. It's meant to be fun!</p>
                <p className="text-l text-center mb-4 spiel">As well as recording, mixing and producing I am an active recording artist and play a number of instruments including piano, guitar and bass. I am always very happy and excited to play on other artists work.</p>
            </div>
            <div className="c_service_div">
                <img 
                    src={fig8} 
                    className="c_service_div__image"
                    alt='figure 8 microphone polar pattern'
                />
            </div>
        </div>
    )
}

export default About