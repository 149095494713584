import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NavBar from './components/NavBar';
import Socials from './components/Socials';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Listen from './pages/Listen';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Rates from './pages/Rates';
import Clients from './pages/Clients';
import Equipment from './pages/Equipment';
import './styles/styles.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className='page'>
        <NavBar />
        <section>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/listen" element={<Listen />} />
            <Route path="/rates" element={<Rates />} />
            <Route path="/me" element={<About />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/equipment" element={<Equipment />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
          </Routes>
        </section>
        <div className='footer'>
          <Socials />
        </div>
      </div>
    </BrowserRouter>
  </React.StrictMode>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
