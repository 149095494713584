import SpotifyPlayer from '../components/SpotifyPlayer';

const Listen = () => {
    return (
        <div className='p_generic_container'>
            <h1 className='c_service_header page-header'>Listen</h1>
            <div className="c_service_div">
                <h2 className="c_service_div__heading">Music</h2>
                <SpotifyPlayer height='575' width='100%'/>
            </div>
         
                    
            <div className="c_service_div">
                <h2 className="c_service_div__heading">TV & Film</h2>
                <div className='bg-white rounded mb-8' style={{maxHeight: "73vh", overflowY: "auto"}}>
                    
                    <div className='flex justify-center mt-8 mb-8'>
                        <iframe 
                            title="vimeo-player" 
                            src="https://player.vimeo.com/video/216199591?h=267d5d226e" 
                            className='c_iframe_vimeo_vid'
                            
                        >
                        </iframe>
                    </div>
                    <p className='text-center mt-5 mb-10 ml-5 mr-5'>
                        The trailer for the short film 'Listen'. Written and Directed by 
                        David Moody and Produced by Emma Mullen. Music by Philip d’Alton. 
                        Made on location in Northern Ireland with the assistance of the 
                        Lottery Fund and Northern Ireland Screen.
                    </p>
                
                    <div className='flex justify-center'>
                        <iframe 
                            title="vimeo-player" 
                            src="https://player.vimeo.com/video/287676907?h=663e4c5948" 
                            className='c_iframe_vimeo_vid'
                        >
                        </iframe>
                    </div>
                    <p className='text-center mt-5 mb-10 ml-5 mr-5'>
                        “Camlo” by David Moody for Northern Ireland Screen. Music by Philip d’Alton.
                    </p>

                    <div className='flex justify-center'>
                        <iframe 
                            className='c_iframe_youtube_vid'
                            src="https://www.youtube.com/embed/71gwc25w-0k" 
                            title='Camlo'
                            
                        >
                        </iframe>
                    </div>
                    <p className='text-center mt-5 mb-10  ml-5 mr-5'>
                        “Long May You Reign” by Master & Dog
                    </p>
                </div> 
                    
                    
            
            </div>
        </div>
    )
}
export default Listen