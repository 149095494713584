const Socials = () => {
    return (
        <section className="bg-white mb-8">
            <div>
                <button onClick={() => window.open("https://open.spotify.com/playlist/6Jencnl7ZE1J6nucLqJg1n?go=1&sp_cid=1b80f155003b68333866003d08e5deb1&utm_source=embed_player_p&utm_medium=desktop&nd=1", "_blank")}><i className="bi bi-spotify i-social"></i></button>
                <button onClick={() => window.open("https://www.facebook.com/phildaltonmusicproduction/", "_blank")}><i className="bi bi-facebook i-social"></i></button>
                <button onClick={() => window.open("https://www.instagram.com/philanddog/", "_blank")}><i className="bi bi-instagram i-social"></i></button>
                <button onClick={() => window.open("mailto:philipdalton@gmail.com", "_blank")}><i className="bi bi-envelope i-social"></i></button>
            </div>   
        </section>
    )
}

export default Socials