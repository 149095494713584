const Equipment = () => {
    return (
        <>
            <div className="p_generic_container">
            <h2 className='c_service_header page-header'>Equipment</h2>
            <div className="c_equipment_header_div">
                <h2 className="c_equipment_div__heading">Recording</h2>
                <p className="c_equipment_div__body">I work as a freelance recording engineer so equipment can vary depending on the studio you wish to use or if you want to record on location somewhere. Please contact me to discuss your project to see what studio or location space would best suit your needs. :)   </p>
            </div>

            <div className="c_equipment_div">
                <h2 className="c_equipment_div__heading">Mixing</h2>
                <ul>
                    <li className="c_equipment_div__list_item">Protools 2020</li>
                    <li className="c_equipment_div__list_item">Logic Pro X</li>
                </ul>
                <h2 className="c_equipment_div__heading">Conversion</h2>
                <ul>
                    <li className="c_equipment_div__list_item">Universal Audio Apollo Quad Core 16 channel ( Channel count can be increased depending on your needs)</li>
                </ul>
                <h2 className="c_equipment_div__heading">Operation</h2>
                <ul>
                    <li className="c_equipment_div__list_item">iMac 2015 27 inch 3.4 GHz Intel Core i5 - 32GB Ram</li>
                </ul>
            </div>
            <div className="c_equipment_div">
                <h2 className="c_equipment_div__heading">Monitoring</h2>
                <ul>
                    <li className="c_equipment_div__list_item">Yamaha NS10M Studio Monitors</li>
                    <li className="c_equipment_div__list_item">Yamaha HS8 Monitors</li>
                    <li className="c_equipment_div__list_item">Grado SR120e Open Back Headphones</li>
                </ul>
                <h2 className="c_equipment_div__heading">Preamps</h2>
                <ul>
                    <li className="c_equipment_div__list_item">Universal Audio (Neve 1073 & UA 610)</li>
                    <li className="c_equipment_div__list_item">Universal Audio Solo 610</li>
                    <li className="c_equipment_div__list_item">Focusrite OctoPre dynamic</li>
                </ul>
            </div>
            <div className="c_equipment_div">
                <h2 className="c_equipment_div__heading">Plug ins</h2>
                <ul>
                    <li className="c_equipment_div__list_item">Universal Audio</li>
                    <li className="c_equipment_div__list_item">Waves</li>
                    <li className="c_equipment_div__list_item">Soundtoys</li>
                    <li className="c_equipment_div__list_item">Slate Digital</li>
                    <li className="c_equipment_div__list_item">Brainworx</li>
                    <li className="c_equipment_div__list_item">+many more …</li>
                </ul>
            </div>
            <div className="c_equipment_div">
                <h2 className="c_equipment_div__heading">Microphones</h2>
                <ul>
                    <li className="c_equipment_div__list_item">Neumann TLM 103 `Condenser Cardioid</li>
                    <li className="c_equipment_div__list_item">AKG 414 matched pair</li>
                    <li className="c_equipment_div__list_item">Aston Spirit Multi Pattern Condenser x 2</li>
                    <li className="c_equipment_div__list_item">Aston Stealth Broadcast quality switchable dynamic</li>
                    <li className="c_equipment_div__list_item">Sm57</li>
                    <li className="c_equipment_div__list_item">Sm58</li>
                    <li className="c_equipment_div__list_item">Rode NT5 x 2</li>
                    <li className="c_equipment_div__list_item">SE R1 Ribbon microphones x 2</li>
                    <li className="c_equipment_div__list_item">Slate Digital ML2 x 3</li>
                    <li className="c_equipment_div__list_item">Sennheiser e845</li>
                    <li className="c_equipment_div__list_item">DI Radial Pro DI</li>
                    <li className="c_equipment_div__list_item">Universal Audio DI x 3</li>
                </ul>
            </div>
            <div className="c_equipment_div">
                <h2 className="c_equipment_div__heading">Instruments</h2>
                <ul>
                    <li className="c_equipment_div__list_item">Nord Stage 2 Ex Stage Piano/ Organ/Synth</li>
                    <li className="c_equipment_div__list_item">Gibson Les Paul Studio</li>
                    <li className="c_equipment_div__list_item">DeArmond Starfire Deluxe Hollowbody electric</li>
                    <li className="c_equipment_div__list_item">Gretsch Jim Dandy small bodied parlour acoustic</li>
                    <li className="c_equipment_div__list_item">Tanglewood acoustic</li>
                    <li className="c_equipment_div__list_item">Yamaha RA-200 Rotary Cab</li>
                    <li className="c_equipment_div__list_item">Fender Blues Deville Tweed Amp</li>
                    <li className="c_equipment_div__list_item">Hiwatt Custom 100 Amp</li>
                    <li className="c_equipment_div__list_item">Ibanez ATK300 Active Bass</li>
                    <li className="c_equipment_div__list_item">Vintage Italian Electric Harmonium</li>
                </ul>
            </div>
            <div className="c_equipment_div">
                <h2 className="c_equipment_div__heading">Software Instruments</h2>
                <ul>
                    <li className="c_equipment_div__list_item">Native Instruments Komplete 12</li>
                    <li className="c_equipment_div__list_item">Spitfire Audio</li>
                </ul>
                <h2 className="c_equipment_div__heading">Pedals</h2>
                <ul>
                    <li className="c_equipment_div__list_item">Route 66</li>
                    <li className="c_equipment_div__list_item">Big Muff</li>
                    <li className="c_equipment_div__list_item">1980’s rare Marshall Guv’nor</li>
                    <li className="c_equipment_div__list_item">Snarling Dog</li>
                    <li className="c_equipment_div__list_item">DD6 delay</li>
                    <li className="c_equipment_div__list_item">Line 6 Verbzilla Reverb</li>
                    <li className="c_equipment_div__list_item">Marshall Vibotrem</li>
                    <li className="c_equipment_div__list_item">Boss Blues Driver</li>
                </ul>
            </div>
        </div>
        </>
    )
}

export default Equipment