import PhotoCarousel from '../components/PhotoCarousel';

const Home = () => {

    return (
        <div className="p_home_container">
            <section className='c_home_div'>
                <PhotoCarousel />
                <section className='c_home_div__text'>
                    Recording / Mixing / Production / Session Musician / Sound Design / Teaching
                </section>
            </section>
        </div>
        
    )
}
export default Home