import '../styles/styles.scss'
import fig8 from '../assets/images/fig8.png';

const Services = () => {
    return (
        <div className="p_generic_container">
            <h2 className='c_service_header page-header'>Services</h2>
            <div className="c_service_div">
                <h2 className="c_service_div__heading">Production</h2>
                <p className="c_service_div__body">I have many years experience with producing music for a wide variety of artists. This can be taking on the role of producing every element of a project or helping with the production of certain aspects. It is different for every project and I am more than happy to be as involved in this role as you would like me to be.</p>
            </div>
            <div className="c_service_div">
                <h2 className="c_service_div__heading">Mixing</h2>
                <p className="c_service_div__body">I provide mixing services for all different kinds of projects. This could be a project previously recorded somewhere else or a home demo that you would like to sound polished and professional.</p>
            </div>
            <div className="c_service_div">
                <h2 className="c_service_div__heading">Recording</h2>
                <p className="c_service_div__body">I currently work out of different studios depending on what a project needs and also depending on the artists budget. I have studied and worked as a recording engineer for 20 years and can cater for whatever your requirements may be. I can also provide remote recording if people want to explore different spaces.</p>
            </div>
            <div className="c_service_div">
                <h2 className="c_service_div__heading">Sound Design</h2>
                <p className="c_service_div__body">I have a Masters Degree in Sonic Arts, specialising in sound design. I have worked on a number of projects in this capacity and it is a great love of mine. Please see my Film & Sound Design page for more information.</p>
            </div>
            <div className="c_service_div">
                <h2 className="c_service_div__heading">Audio Restoration</h2>
                <p className="c_service_div__body">I provide a service of audio restoration which involves cleaning up audio that may have been recorded badly or removing background noise or audio artefacts from recordings such as interviews.</p>
            </div>
            <div className="c_service_div">
                <h2 className="c_service_div__heading">Learning</h2>
                <p className="c_service_div__body">As more and more people are able to record from home I provide tutorials about recording from home. I can cover all sorts of subjects from getting your room to sound as good as possible to learning mic placement, operating your DAW or what equipment to buy that would best suit your needs.</p>
            </div>
            <div className="c_service_div">
                <img 
                    src={fig8} 
                    className="c_service_div__image"
                    alt='figure 8 microphone polar pattern'
                />
            </div>
        </div>
    )
}

export default Services