import fig8 from '../assets/images/fig8.png';

const Rates = () => {

    return (
        <div className='p_generic_container'>
            <h1 className='c_service_header page-header'>Rates</h1>
            <div className="c_about_div">

                    <p className='text-l mb-8'>Recording rates starting at £320 a day (including studio hire)</p>
                    <p className='text-l mb-8'>Mixing rates starting at £250 per track.</p>
                    <p className='text-l text-center spiel'>Rates are dependent on a number of factors depending on what you would like to achieve. I work out of a number of great studios and also from my own home studio so this can also affect the cost of each project.  Please don’t hesitate to get in touch to discuss any aspects of your project, you can do this via the contact page above.</p>

            </div>
            <div className="c_service_div">
                <img 
                    src={fig8} 
                    className="c_service_div__image"
                    alt='figure 8 microphone polar pattern'
                />
            </div>
        </div>
    )
}

export default Rates