import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import fig8 from '../assets/images/fig8.png';

const Contact = () => {
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [message, setMessage] = useState<string>();
    const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
    let form: any;

    const postMessage = async () => {
        if (form.checkValidity()) {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type' : 'application/json'},
                body: JSON.stringify({
                    "name": name,
                    "email": email,
                    "message": message
                })
            }

            const response = await fetch("https://weeb54jmsc.execute-api.eu-west-1.amazonaws.com/production/contact", requestOptions)
            const data = response
            if (data.status === 200) {
                setDisableSubmit(true)
            } else {
                setDisableSubmit(false)
            }
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        form = event.currentTarget;
        
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        setDisableSubmit(true)
        postMessage();
    }

    return (
        <div className='p_generic_container'>
            <h1 className='c_service_header page-header'>Get in touch</h1>
            <div className="c_contact_div">
                <div className='c_form_div'>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} >
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Name"
                                    onChange={(event)=> setName(event.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide your name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control 
                                    required
                                    type="email" 
                                    placeholder="name@example.com" 
                                    onChange={(event)=> setEmail(event.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid email address.
                                </Form.Control.Feedback>
                            </Form.Group>
                        
                            <Form.Group controlId="formMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows={3} 
                                    required
                                    placeholder='My message' 
                                    onChange={(event)=> setMessage(event.target.value)}/>
                                <Form.Control.Feedback type="invalid">
                                    What is your message?
                                </Form.Control.Feedback>
                            </Form.Group>
                        
                        <Button 
                            className='mt-2' 
                            variant={disableSubmit ? "success" : "secondary"} 
                            as="input" 
                            type="submit" 
                            active 
                            disabled={disableSubmit} 
                            value={disableSubmit ? 'Sent' : 'Submit'}
                        >
                        </Button>
                    </Form>
                </div>
                
            </div>
            <div className='i_contact mt-2 c_service_div'>
                <i className="bi bi-envelope"> philipdalton@gmail.com</i>
                <i className="bi bi-telephone mb-2"> 07874612911</i>
                <img 
                    src={fig8} 
                    className="c_service_div__image"
                    alt='figure 8 microphone polar pattern'
                />
            </div>
            
        </div>
    )
}

export default Contact