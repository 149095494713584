import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import supercardioid from '../assets/images/supercardioid.png';

const NavBar = () => {
    return (
        <> 
            <Navbar  bg="white" expand="md" className="mb-3 nav">
            <Container>
                <Navbar.Brand href="#" className='brand'>
                    <img
                        alt="super-cardioid microphone polar pattern"
                        src={supercardioid}
                        width="60"
                        height="60"
                        className="d-inline-block"
                    />{' '}
                        Philip d'Alton
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
                <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-md`}
                aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                placement="end"
                >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`} className='brand'>
                        Philip d'Alton
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3 brand">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="Services">Services</Nav.Link>
                        <NavDropdown title="About" id="basic-nav-dropdown">
                            <NavDropdown.Item href="me">Me</NavDropdown.Item>
                            <NavDropdown.Item href="clients">Clients</NavDropdown.Item>
                            <NavDropdown.Item href="rates">Rates</NavDropdown.Item>
                            <NavDropdown.Item href="equipment">Equipment</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="listen">Listen</Nav.Link>
                        <Nav.Link href="contact">Contact</Nav.Link>
                    </Nav>
                </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
            </Navbar>
        </>
    )
}

export default NavBar